import DateFormat from '@utils/date/format';

export interface IJobOpeningImageListItemVO {
  sn: number;
  companyName: string;
  positionName: string;
  postStartDateTime: string;
  postEndDateTime: string;
  companyLogoUrl: string;
  representativeImageUrl: string;
  bookmarkYn?: boolean;
}

export class JobOpeningImageListItemVO {
  sn: number;
  companyName: string;
  positionName: string;
  postStartDateTime: string | null;
  postEndDateTime: string | null;
  dDay: string | null;
  companyLogoUrl: string;
  representativeImageUrl: string;
  bookmarkYn: boolean;

  constructor(dto: IJobOpeningImageListItemVO) {
    this.sn = dto.sn;
    this.companyName = dto.companyName;

    this.positionName = dto.positionName;

    this.postStartDateTime = dto.postStartDateTime;
    this.postEndDateTime = dto.postEndDateTime
      ? DateFormat.format(new Date(dto.postEndDateTime), 'yyyy.MM.dd(week) HH:mm')
      : null;

    this.dDay = DateFormat.formatPositionDDayText(dto.postEndDateTime ? new Date(dto.postEndDateTime) : null);
    this.companyLogoUrl = dto.companyLogoUrl;
    this.representativeImageUrl = dto.representativeImageUrl;
    this.bookmarkYn = !!dto.bookmarkYn;
  }
}
