import Skeleton from '@components/_atoms/Skeleton';

import classnames from 'classnames/bind';
import styles from './recruitmentList.module.scss';
const cx = classnames.bind(styles);

const RecruitmentListSkeleton = () => {
  return (
    <div className={cx('section')}>
      <div className={cx('skeleton')}>
        <Skeleton width={200} height={32} />
        <div className={cx('list')}>
          <Skeleton width={282} height={318} borderRadius={16} />
          <Skeleton width={282} height={318} borderRadius={16} />
          <Skeleton width={282} height={318} borderRadius={16} />
          <Skeleton width={282} height={318} borderRadius={16} />
          <Skeleton width={282} height={318} borderRadius={16} />
          <Skeleton width={282} height={318} borderRadius={16} />
          <Skeleton width={282} height={318} borderRadius={16} />
          <Skeleton width={282} height={318} borderRadius={16} />
        </div>
      </div>
    </div>
  );
};

export default RecruitmentListSkeleton;
