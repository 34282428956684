import React from 'react';

import { useLoginModal } from '@components/loginModal/LoginModalContext';
import PersonalInfo from './personalInfo';
import classnames from 'classnames/bind';
import styles from './personalStatus.module.scss';
const cx = classnames.bind(styles);

interface Props {
  viewRecommendCard: boolean;
}

const PersonalStatus = ({ viewRecommendCard }: Props) => {
  const { openLoginModal, isLogin } = useLoginModal();

  return (
    <div className={cx('personalWrap')}>
      <div className={cx('personalCard')}>
        {isLogin ? (
          <>
            <PersonalInfo viewRecommendCard={viewRecommendCard} />
          </>
        ) : (
          <div className={cx('dashBoard', 'noLogin')}>
            <div className={cx('subTitle')}>프로필 채우고 나에게 딱 맞는</div>
            <div className={cx('title')}>
              공고 추천과 기업 매칭의
              <br />
              기회를 잡아보세요!
            </div>
            <a aria-label={'프로필 입력하기'} className={cx('btnLogin')} role="button" onClick={() => openLoginModal()}>
              프로필 입력하기
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
export default PersonalStatus;
