import React, { useRef } from 'react';
import Slider from 'react-slick';
import Link from 'next/link';
import SliderButton from '@components/common/sliderButton';
import Icon from '@components/common/assets';
import Text from '@components/common/text';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { usePositionProfileRecommend } from '@repository/position/usePosition';
import useProfileConditions from '@repository/profiles/useProfileConditions';
import PersonalStatus from './pesonalStatus';
import PersonalPositionListItem from './personalPositionListItem';
import { PositionRecommendDto } from '@domain/rs/index/PositionJobListCardRs';
import Colors from '@domain/constant/colors';
import { FontStyle } from '@domain/constant/fontStyle';

import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './personalPositionList.module.scss';
const cx = classnames.bind(styles);

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'prev'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnPrev')}
    />
  );
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'next'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnNext')}
    />
  );
};

const PersonalPositionList = () => {
  const dragging = useRef(false);
  const settings = {
    infinite: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 300,
    dots: true,
    cssEase: 'ease-in-out',
    draggable: true,
    beforeChange: () => (dragging.current = true),
    afterChange: () => (dragging.current = false),
  };

  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: positionListData } = usePositionProfileRecommend({ hide: true, pageSize: 11 });
  const { data: conditionsData } = useProfileConditions(isLogin);
  const filterPositionList = positionListData?.positions.filter((position) => position.companySn !== 361);
  const viewRecommendCard = (filterPositionList?.length ?? 0) <= 3;

  const myJobTitlesCodes = conditionsData?.jobGroups
    .map((jobGroup) => jobGroup.jobTitles.map((jobTitle) => jobTitle.jobTitleCodes))
    .flat();

  const positionListEls = filterPositionList?.map((position: PositionRecommendDto) => {
    const fitJobTitle = position.jobTitles.find((jobTitle) => myJobTitlesCodes?.includes(jobTitle.code));

    return (
      <div key={position.positionSn}>
        <PersonalPositionListItem data={position} dragging={() => dragging.current} fitJobTitle={fitJobTitle} />
      </div>
    );
  });

  return (
    <div className={cx('section')}>
      <div className={cx('sectionInner')}>
        <div className={cx('recruitmentListArea')}>
          <div className={cx('sectionTitleArea')}>
            <strong className={cx('sectionTitle')}>
              {isLogin ? '내 프로필 기반 추천 포지션' : '로그인하고 내 조건에 맞는 포지션 추천받기'}
            </strong>
          </div>

          {/* [D] 개인화 포지션 노출 */}
          <div className={cx('recruitmentListInner')}>
            <Slider className={cx('positionSlider')} {...settings}>
              <div>
                <PersonalStatus viewRecommendCard={!filterPositionList?.length} />
              </div>
              {positionListEls}

              {viewRecommendCard && <NotPositionListCard />}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalPositionList;

const NotPositionListCard = () => {
  return (
    <Link className={cx('notPositionListCard')} href={RouteUrl.POSITION}>
      <div className={cx('icon')}>
        <Icon name="addLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
      </div>

      <Text fontStyle={FontStyle.B3P_B} color={Colors.C_COOL_GRAY_60}>
        원하는 조건을 직접 선택해서 <br /> 나에게 맞는 포지션을 확인해 보세요.
      </Text>
    </Link>
  );
};
