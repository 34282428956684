import React, { FC, useState } from 'react';
import Icon from '@common/assets';
import EllipsisText from '@common/ellipsisText';
import { JobOpeningImageListItemVO } from '@domain/vo/common/JobOpeningImageListItemVO';
import Image from '@components/common/image';
import { useAddBookMark, useDeleteBookMark } from '@repository/job-postings/useJobPostings';
import Colors from '@domain/constant/colors';

import { dummyImgSrc } from '@domain/constant/img/positionDummyImgSrc';
import classnames from 'classnames/bind';
import styles from './jobOpeningImageListItem.module.scss';
const cx = classnames.bind(styles);

interface JobOpeningImageListItemProps {
  positionData: JobOpeningImageListItemVO;
  onOpenJobPostModal: () => void;
  clickAfterBookmarkBtn?: () => void;
  visibleBookmark?: boolean;
}

const JobOpeningImageListItem: FC<JobOpeningImageListItemProps> = ({
  positionData,
  onOpenJobPostModal,
  clickAfterBookmarkBtn,
  visibleBookmark = true,
}) => {
  const { companyName, sn, positionName, postEndDateTime, dDay, companyLogoUrl, representativeImageUrl, bookmarkYn } =
    positionData;
  const [bookmark, setBookmark] = useState(bookmarkYn);

  const { mutateAsync: addBookmark } = useAddBookMark(() => {
    clickAfterBookmarkBtn?.();
    setBookmark(true);
  });
  const { mutateAsync: deleteBookmark } = useDeleteBookMark(() => {
    clickAfterBookmarkBtn?.();
    setBookmark(false);
  });

  const handleBookMark = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (bookmark) deleteBookmark(sn);
    else addBookmark(sn);
  };

  return (
    <a className={cx('listItem')} role="button" onClick={onOpenJobPostModal}>
      <div className={cx('imgArea')}>
        <Image
          src={representativeImageUrl}
          alt={`${companyName} 회사`}
          width={280}
          height={176}
          pointer
          ErrorImage={<Image src={dummyImgSrc} alt="dummyImg" fill pointer />}
        />
      </div>
      <div className={cx('companyInfoArea')}>
        <div className={cx('logo')}>
          <Image
            src={companyLogoUrl}
            alt={`${companyName} 로고`}
            width={48}
            height={48}
            ErrorImage={<Image src={dummyImgSrc} alt={`${companyName} 로고`} fill />}
          />
        </div>
        <div className={cx('companyName')}>{companyName}</div>

        <div className={cx('positionName')}>
          <EllipsisText
            text={positionName}
            type={'width'}
            offset={216}
            customClassName={cx('jobPostTitleWrap')}
            tooltipType={'top'}
          />
        </div>

        <div className={cx('dateArea')}>
          {postEndDateTime && <div className={cx('date')}>{postEndDateTime}</div>}
          {dDay && <div className={cx('dDay')}>{dDay}</div>}
        </div>
      </div>

      {visibleBookmark && (
        <button className={cx('btnBookmark')} onClick={handleBookMark}>
          {/* [D] 북마크 된 경우 'IconBookmarkLightFilled' 아이콘 적용 */}
          {bookmark ? (
            <Icon name="bookmarkLightFilled" width={32} height={32} fill={Colors.C_WHITE} />
          ) : (
            <Icon name="bookmarkLight" width={32} height={32} fill={Colors.C_WHITE} />
          )}
          <span className={cx('hidden')}>북마크</span>
        </button>
      )}
    </a>
  );
};

export default JobOpeningImageListItem;
