import React from 'react';
import Link from 'next/link';
import Icon from '@components/common/assets';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useApplicationStatus, useBookmarkList, useMatchingStatus } from '@repository/match';
import { useLoginModal } from '@components/loginModal/LoginModalContext';
import { LogTypeEnum } from '@/src/logging/LogType';
import MatchSwitch from './matchSwitch';
import MatchTooltip from './matchTooltip';
import RollingBanner from '../rollingBanner';
import { MatchingStatusListTabEnum } from '@domain/rq/match/MatchingStatusListRq';
import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './mainDashboard.module.scss';

const cx = classnames.bind(styles);

const MainDashboard = () => {
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: matchStatus } = useMatchingStatus(isLogin);
  const { data: bookmarkData } = useBookmarkList(isLogin);
  const { data } = useApplicationStatus({ enabled: isLogin });

  const profileImageUrl = userInfo?.profileImageUrl;
  const { offerCount, newOfferYn } = matchStatus || {};
  const { openLoginModal } = useLoginModal();

  return (
    <div className={cx('mainInfo')} id={LogTypeEnum.log_main_dashboard}>
      {/* 대시보드 */}
      <div className={cx('dashBoardInner')}>
        {isLogin ? (
          <div className={cx('dashBoard', 'yesLogin')}>
            {/* 매칭정보 */}
            <div className={cx('info')}>
              <div className={cx('personalMessage')}>
                <Link
                  aria-label={`프로필`}
                  className={cx('profile', { default: !profileImageUrl })}
                  style={profileImageUrl ? { backgroundImage: `url(${profileImageUrl})` } : {}}
                  href={RouteUrl.PROFILE}
                >
                  {!profileImageUrl && <Icon name="profileDefault" width={64} height={64} />}
                </Link>
                <MatchTooltip />
              </div>
              <div className={cx('status')}>
                <div className={cx('matchingSwitch')}>
                  <div className={cx('settingText')}>잡다매칭</div>
                  <MatchSwitch />
                </div>
                <div className={cx('title')}>{userInfo?.name}님의 취업현황</div>
              </div>
            </div>

            {/* 추천/열람/제안/지원현황 수 */}
            {/* [D] 새로운 알람이 있을 때 count옆에 new가 붙음 */}
            <div className={cx('matchingStatus')}>
              <Link href={RouteUrl.MY_PAGE_BOOKMARK} className={cx('listItem')}>
                <>
                  <div className={cx('count')}>{bookmarkData?.pages[0].pages.totalElements ?? 0}</div>
                  <div className={cx('title')}>북마크</div>
                </>
              </Link>
              <div className={cx('divider')} />
              <Link
                className={cx('listItem')}
                href={`${RouteUrl.MATCH_APPLY}?tab=${MatchingStatusListTabEnum.MATCH_OFFER}`}
              >
                <>
                  <div className={cx('count', { new: newOfferYn })}>{offerCount}</div>
                  <div className={cx('title')}>입사지원 제안</div>
                </>
              </Link>
              <div className={cx('divider')} />
              <Link className={cx('listItem')} href={RouteUrl.MY_PAGE_APPLY}>
                <>
                  {/* [D] 지원 완료 수 */}
                  <div className={cx('count')}>{data?.applicationCompleteCount ?? 0}</div>
                  <div className={cx('title')}>지원현황</div>
                </>
              </Link>
            </div>
          </div>
        ) : (
          <div className={cx('dashBoard', 'noLogin')}>
            <span className={cx('title')}>로그인하고 잡다 매칭에 참여해 보세요.</span>
            <a aria-label={'로그인'} className={cx('btnLogin')} role="button" onClick={() => openLoginModal()}>
              로그인
            </a>
          </div>
        )}
      </div>

      {/* rollingBanner */}
      <RollingBanner />
    </div>
  );
};

export default MainDashboard;
