import { useEffect, useRef } from 'react';

type ParametersType = (...args: any[]) => any;

export default function useDebouncedCallback<F extends ParametersType>(
  callback: F,
  delay: number,
  immediate = false,
  dep?: React.DependencyList,
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback, dep]);

  const debounced = useRef(
    debounce(
      (...args: Parameters<F>) => {
        callbackRef.current(...args);
      },
      delay,
      immediate,
    ),
  );

  return debounced.current;
}

const debounce = <F extends ParametersType>(func: F, delay: number, immediate = false) => {
  let timeoutId: NodeJS.Timeout | null = null;
  let firstCall = immediate;

  return function _debounced(...args: Parameters<F>) {
    if (firstCall) {
      func(...args);
      firstCall = false;
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
