import React, { FC } from 'react';
import Link from 'next/link';
import Text from '@components/common/text';

import Icon from '@components/common/assets';
import { useMatchProfile } from '@repository/match';
import { useUserInfo } from '@repository/auth/useUserInfo';
import Colors from '@domain/constant/colors';
import { FontStyle } from '@domain/constant/fontStyle';
import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './personalInfo.module.scss';
const cx = classnames.bind(styles);

interface PersonalCardProps {
  contents: string;
  desc: React.ReactNode;
  progressPercentage: number;
  button: string;
  noProfile?: boolean;
}

const PersonalCard: FC<PersonalCardProps> = ({ contents, desc, progressPercentage, button, noProfile }) => {
  const { data: userInfo } = useUserInfo();
  return (
    <div className={cx('personalCard', { noProfile: noProfile })}>
      <div className={cx('title')}>
        {userInfo?.name}님의
        <br />
        프로필 완성도
      </div>

      {/* 프로필 완성도 */}
      <div className={cx('progressArea')}>
        <span className={cx('progressText')}>{progressPercentage}%</span>
        <div className={cx('progressBarWrap')}>
          <span className={cx('progressBarBackGround')} />
          <span className={cx('progressBar')} style={{ width: `${progressPercentage}%` }} />
        </div>
      </div>

      {/* 개인화 내용 */}
      <div className={cx('cardContents')}>
        {contents}
        <div className={cx('desc')}>{desc}</div>
      </div>

      <Link href={RouteUrl.PROFILE} legacyBehavior>
        <a className={cx('btnBasic')} role="button">
          {button}
        </a>
      </Link>
    </div>
  );
};

const NotPositionCard = () => {
  const { data: userInfo } = useUserInfo();
  return (
    <div className={cx('personalCard', 'noProfile')}>
      <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60}>
        현재 {userInfo?.name}님의
      </Text>

      <Text fontStyle={FontStyle.H5_B} styles={{ marginTop: 8 }}>
        <>
          프로필 기반
          <br />
          맞춤 포지션이 없습니다.
        </>
      </Text>

      <Link href={RouteUrl.PROFILE} className={cx('btnBasic')}>
        선호정보 입력하기
      </Link>
    </div>
  );
};
interface Props {
  viewRecommendCard: boolean;
}

const PersonalInfo = ({ viewRecommendCard }: Props) => {
  const { data: userInfo } = useUserInfo();
  const { data: matchProfile } = useMatchProfile(!!userInfo?.id);

  const getContents = () => {
    const percentage = matchProfile?.profile?.completionRate ?? 0;
    switch (percentage) {
      case 0:
        return (
          <PersonalCard
            noProfile={true}
            contents="맞춤 포지션 추천받는 방법"
            desc={
              <>
                <div className={cx('howTo')}>
                  <Icon name="checkLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
                  프로필 완성도
                </div>
                <div className={cx('howTo')}>
                  <Icon name="checkLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
                  선호 정보 채우기
                </div>
              </>
            }
            progressPercentage={percentage}
            button="프로필 채우기"
          />
        );
      case 100:
        return (
          <PersonalCard
            contents="프로필이 완성됐어요!"
            desc={`${userInfo?.name}님의 프로필을 바탕으로\n관심 있을 만한 포지션을 준비했어요.`}
            progressPercentage={percentage}
            button="내 프로필 확인하기"
          />
        );

      default:
        return (
          <PersonalCard
            contents="더 Fit한 포지션 추천받는 방법"
            desc={`더 많은 프로필 정보를 채운다면 나에게\n딱 맞는 포지션을 추천받을 수 있습니다.`}
            progressPercentage={percentage}
            button="프로필 완성하기"
          />
        );
    }
  };

  if (viewRecommendCard) return <NotPositionCard />;

  return <div className={cx('personalWrap')}>{getContents()}</div>;
};
export default PersonalInfo;
