import { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Switch from '@components/common/switch';
import MatchProposalModals, { MatchProposalModalType } from '@components/common/matchSwitch/matchProposalModals';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useProfilesType } from '@repository/profiles/useProfile';
import { MATCH_CONFIG_STATUS_QUERY_KEY, useGetMatchConfigStatus, useUpdateMatchConfigStatus } from '@repository/match';
import { LogTypeEnum } from '@/src/logging/LogType';
import { queryClient } from '@utils/queryClient';
import { RouteUrl, V2RouteUrl } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './matchSwitch.module.scss';

const cx = classnames.bind(styles);

export const useMatch = () => {
  const { query, pathname } = useRouter();
  const [modalState, setModalState] = useState<MatchProposalModalType>(null);

  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;

  const { data: matchConfigStatus } = useGetMatchConfigStatus(isLogin);
  const { data: profilesTypeData } = useProfilesType(isLogin);

  const { mutateAsync: updateMatchConfigStatusMutateAsync } = useUpdateMatchConfigStatus(async () => {
    // 제안 받기 | 제안 안받기 후 제안 받기 여부 새로고침
    await queryClient.refetchQueries(MATCH_CONFIG_STATUS_QUERY_KEY, { active: true, exact: true });
  });

  const handleMatchingConfigYn = async () => {
    if (matchConfigStatus?.matchingConfigYn) {
      // 잡다매칭 제안 받기 취소일 경우
      setModalState('MATCH_CANCEL_BENEFITS');
    } else {
      // 프로필 | 역량검사 선택 등 매칭에 필요한 정보가 없을 경우
      if (matchConfigStatus?.isNeedTodo) {
        setModalState('MATCH_NEED_TODO');
        return;
      }

      // 프로필 | 역량검사 선택 등 완료됐을 경우 매칭 제안 토글
      const sourcePage = pathname === V2RouteUrl.MATCH_APPLY || query.source_page === 'MATCH' ? 'MATCH' : undefined;
      await updateMatchConfigStatusMutateAsync({
        useYn: true,
        sourcePage,
      });
      setModalState('MATCH_APPLY_SUCCESS');
    }
  };

  return {
    matchConfigStatus,
    profilesTypeData,
    handleMatchingConfigYn,
    matchingConfigYn: matchConfigStatus?.matchingConfigYn,
    modalState,
    setModalState,
  };
};

const MatchSwitch = () => {
  const { matchConfigStatus, profilesTypeData, handleMatchingConfigYn, matchingConfigYn, modalState, setModalState } =
    useMatch();

  const handleOnChange = () => {
    handleMatchingConfigYn();
  };

  if (!matchConfigStatus || !profilesTypeData) return null;

  return (
    <>
      <div className={cx('proposalContainer')} id={LogTypeEnum.log_match_switch}>
        <div className={cx('switchArea')}>
          {matchingConfigYn ? (
            <span className={cx('link', 'linkOn')}>ON</span>
          ) : (
            <Link href={RouteUrl.MATCH_APPLY} legacyBehavior>
              <a role="button" className={cx('link', 'linkOff')}>
                OFF
              </a>
            </Link>
          )}
          {!matchingConfigYn && (
            <Switch className={cx('matchSwitch')} onChange={handleOnChange} checked={matchingConfigYn} />
          )}
        </div>
      </div>

      {/* 제안 받기 / 제안 안받기 시 노출 모달 */}
      <MatchProposalModals
        modalState={modalState}
        setModalState={setModalState}
        matchConfigStatus={matchConfigStatus}
        profilesTypeData={profilesTypeData}
      />
    </>
  );
};

export default MatchSwitch;
