import Skeleton from '@components/_atoms/Skeleton';

import classnames from 'classnames/bind';
import styles from './jobPostList.module.scss';
const cx = classnames.bind(styles);

const JobPostListSkeleton = () => {
  return (
    <div className={cx('section')}>
      <div className={cx('skeleton')}>
        <Skeleton width={200} height={32} />
        <Skeleton width={240} height={20} style={{ marginTop: 8 }} />
        <div className={cx('list')}>
          <Skeleton width={282} height={198} borderRadius={16} />
          <Skeleton width={282} height={198} borderRadius={16} />
          <Skeleton width={282} height={198} borderRadius={16} />
          <Skeleton width={282} height={198} borderRadius={16} />
          <Skeleton width={282} height={198} borderRadius={16} />
          <Skeleton width={282} height={198} borderRadius={16} />
          <Skeleton width={282} height={198} borderRadius={16} />
          <Skeleton width={282} height={198} borderRadius={16} />
        </div>
      </div>
    </div>
  );
};

export default JobPostListSkeleton;
