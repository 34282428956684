import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { RemoteAdsRepo } from './AdsRepo';

const repo = new RemoteAdsRepo();

const keys = ['ads', 'main-banner'];
const fetchAdsMainBannerRolling = async () => {
  const { data } = await repo.fetchAdsMainBannerRolling();
  return data;
};

export const useAdsMainBannerRolling = () => {
  return useQuery([...keys, 'rolling'], fetchAdsMainBannerRolling);
};

//메인배너 광고 조회
export const fetchMainBanners = async () => {
  const { data } = await repo.fetchMainBanners();
  return data;
};

//메인배너 광고 클릭수 증가
const fetchMainBannerClicksLog = async (mainBannerSn: number) => {
  const { data } = await repo.fetchMainBannerClicksLog(mainBannerSn);
  return data;
};

//메인배너 광고 노출수 증가
const fetchMainBannerShowsLog = async (mainBannerSn: number) => {
  const { data } = await repo.fetchMainBannerShowsLog(mainBannerSn);
  return data;
};

export function useMainBanners() {
  return useQuery([...keys, 'mainBannerList'], fetchMainBanners);
}

export function useMainBannerClicksLog() {
  return useMutation((mainBannerSn: number) => fetchMainBannerClicksLog(mainBannerSn), {
    onError: (err: AxiosError) => {
      console.error(err.response?.data.message);
      return;
    },
  });
}

export function useMainBannerShowsLog() {
  return useMutation((mainBannerSn: number) => fetchMainBannerShowsLog(mainBannerSn), {
    onError: (err: AxiosError) => {
      console.error(err.response?.data.message);
      return;
    },
  });
}
