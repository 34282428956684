import React, { useEffect, useState } from 'react';
import Icon from '@components/common/assets';
import { useAccaTestResultStore } from '@stores/AccaTestResultStore';
import { useGetMatchConfigStatus } from '@repository/match';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useAccCenterApplyRecent } from '@repository/acc/center/useAccaCenter';
import Colors from '@domain/constant/colors';
import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export const useMatch = () => {
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;

  const { data: matchConfigStatus } = useGetMatchConfigStatus(isLogin);

  return {
    matchConfigStatus,
    matchingConfigYn: matchConfigStatus?.matchingConfigYn,
  };
};

const compare = (dateResult: Date, dateBasic: Date) => {
  const diffTime = Math.abs(dateBasic.getTime() - dateResult.getTime());
  const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
  return diffMonths;
};

const MatchTooltip = () => {
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const [isVisible, setIsVisible] = useState(true);
  const fetchAccResults = useAccaTestResultStore((state) => state.fetchAccResults);
  const accResults = useAccaTestResultStore((state) => state.accResults);
  const { matchingConfigYn } = useMatch();
  const [message, setMessage] = useState('');
  const applicationDate = new Date(accResults?.resultRecords?.[0]?.applicationDate ?? '');
  const now = new Date();
  const [messageLink, setMessageLink] = useState('');

  //NeedAdditional
  const { data: applyRecentData } = useAccCenterApplyRecent(isLogin);
  const accNeedAdditional = !!applyRecentData?.applies?.some((apply) => !apply.isEnd);

  useEffect(() => {
    fetchAccResults();
  }, []);

  useEffect(() => {
    if (accResults && matchingConfigYn !== undefined) {
      if (!accResults?.resultRecords?.length) {
        setMessage('역량검사를 응시하고 잡다에서 취업 준비를 시작하세요!');
        setMessageLink(RouteUrl.ACCA_TEST_GATE);
      } else if (!matchingConfigYn) {
        setMessage('매칭 ON을 하면 입사지원 제안을 받을 수 있어요!');
        setMessageLink(RouteUrl.MY_PAGE_APPLY);
      } else if (accNeedAdditional) {
        setMessage('역량검사 추가 응시 완료하고 결과표 받아가세요!');
        setMessageLink(RouteUrl.ACCA_TEST_LIST);
      } else if (compare(applicationDate, now) >= 6) {
        setMessage('역량검사로 당신의 가능성을 다시 한번 확인해 보세요!');
        setMessageLink(RouteUrl.ACCA_TEST_GATE);
      } else {
        setMessage('프로필을 더 상세하게 작성하면 매칭 확률이 높아져요!');
        setMessageLink(RouteUrl.PROFILE);
      }
    }
  }, [accResults, matchingConfigYn]);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible || !accResults || matchingConfigYn === undefined) {
    return null;
  }

  return (
    <div className={cx('tooltipInner')}>
      <div className={cx('tooltipBox')}>
        <a href={messageLink} className={cx('tooltip')}>
          {message}
        </a>
        <button className={cx('closeBtn')} onClick={handleClose}>
          <Icon name="closeLight" width={24} height={24} fill={Colors.C_WHITE} />
        </button>
      </div>
    </div>
  );
};

export default MatchTooltip;
