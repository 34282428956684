import React from 'react';
import MainBanner from './mainBanner';
import MainDashboard from './mainDashboard';
import classnames from 'classnames/bind';
import styles from './mainTopArea.module.scss';

const cx = classnames.bind(styles);

const MainTopArea = () => {
  return (
    <div className={cx('mainTopInner')}>
      <MainBanner />
      <MainDashboard />
    </div>
  );
};

export default MainTopArea;
