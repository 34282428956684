import React, { FC } from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import Image from '@components/common/image';
import Text from '@components/common/text';
import { useToast } from '@components/common/toast';
import { useLoginModal } from '@components/loginModal/LoginModalContext';
import { useAddBookmark, useDeleteBookmark } from '@repository/position/usePosition';
import { LogTypeEnum } from '@/src/logging/LogType';
import { PositionRecommendDto } from '@domain/rs/index/PositionJobListCardRs';
import JobTitleDto from '@domain/rs/code/JobTitleRs';
import Colors from '@domain/constant/colors';
import { FontStyle } from '@domain/constant/fontStyle';
import { dummyImgSrc } from '@domain/constant/img/positionDummyImgSrc';
import { V2Route } from '@utils/routeUtil';
import NumberFormat from '@utils/number/format';
import useCustomOptimistic from '@utils/hooks/useCustomOptimistic';
import classnames from 'classnames/bind';
import styles from './personalPositionListItem.module.scss';
const cx = classnames.bind(styles);

interface JobOpeningImageListItemProps {
  data: PositionRecommendDto;
  dragging?: () => void;
  fitJobTitle?: JobTitleDto;
}

const PersonalPositionListItem: FC<JobOpeningImageListItemProps> = ({ data, dragging, fitJobTitle }) => {
  const Toast = useToast();
  const { mutate: deleteBookmark } = useDeleteBookmark();
  const { mutate: addBookmark } = useAddBookmark();
  const { isLogin, openLoginModal } = useLoginModal();
  const { companyName, positionName, bookmarkYn, teamImageUrl } = data;

  const onError = () => {
    optimisticError();
    Toast({ iconType: 'info', type: 'error', content: '오류가 발생했어요. 다시 시도해주세요.' });
  };

  const onOptimisticUpdate = (value: boolean) => {
    const options = {
      onSuccess: optimisticSuccess,
      onError,
    };

    if (value) {
      addBookmark(data.positionSn, options);
    } else {
      deleteBookmark(data.positionSn, options);
    }
  };

  const { viewValue, updateValue, optimisticError, optimisticSuccess } = useCustomOptimistic(
    bookmarkYn,
    onOptimisticUpdate,
    500,
    true,
  );

  const notJobTitles = !data.jobTitles.length;

  const handleBookMark = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isLogin) {
      openLoginModal();
      return;
    }

    if (viewValue) {
      Toast({ iconType: 'bookmark', content: '[지원현황] > 북마크에서 삭제되었어요.', size: 'content' });
    } else {
      Toast({ iconType: 'bookmark', content: '[지원현황] > 북마크에 저장되었어요.', size: 'content' });
    }

    updateValue();
  };

  return (
    <Link
      className={cx('listItem')}
      href={V2Route.getPositionJDPage(data.positionSn)}
      id={LogTypeEnum.log_profile_recommend_position}
      onClick={(e) => {
        if (dragging?.()) {
          e.preventDefault();
        }
      }}
    >
      {/* [D] 썸네일 이미지 개발 필요 */}
      <div className={cx('imgWrap')}>
        <div className={cx('imgArea')}>
          {teamImageUrl ? (
            <Image
              src={teamImageUrl}
              alt={`${companyName} 회사`}
              className={cx('thumbnailImg')}
              width={280}
              height={180}
              pointer
            />
          ) : (
            <div className={cx('dummyImg')}>
              <Image src={dummyImgSrc} alt="dummyImg" fill pointer />
            </div>
          )}
          <div className={cx('dim')} />
        </div>

        {/* [D] 북마크 영역 개발 필요 */}
        <button className={cx('btnBookmark')} onClick={handleBookMark}>
          {/* [D] 북마크 된 경우 'IconBookmarkLightFilled' 아이콘 적용 */}
          {viewValue ? (
            <Icon name="bookmarkLightFilled" width={32} height={32} fill={Colors.C_WHITE} />
          ) : (
            <Icon name="bookmarkLight" width={32} height={32} fill={Colors.C_WHITE} />
          )}
          <span className={cx('hidden')}>북마크</span>
        </button>
      </div>

      {/* [D] 포지션 정보 */}
      <div className={cx('companyInfoArea')}>
        <div className={cx('info')}>
          <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60}>
            {companyName}
          </Text>
          <Text className={cx('positionName')} fontStyle={FontStyle.B1_B} styles={{ marginTop: 8 }}>
            {positionName}
          </Text>
        </div>

        <div className={cx('bottomArea')}>
          <div className={cx('fitList')}>
            <div className={cx('divider')} />
            <div className={cx('fitItemArea')}>
              <div className={cx('fitItem')}>
                <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60}>
                  {notJobTitles ? '직군' : '직무'}
                </Text>
                <Text
                  fontStyle={FontStyle.B3_M}
                  color={isLogin && fitJobTitle ? Colors.C_GREEN_150 : Colors.C_COOL_GRAY_70}
                >
                  {notJobTitles ? data.jobGroupName : fitJobTitle?.name ?? data.jobTitles[0]?.name}
                </Text>
              </div>
              <div className={cx('fitItem', { hidden: !data.locationCode })}>
                <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60}>
                  위치
                </Text>
                <Text
                  fontStyle={FontStyle.B3_M}
                  color={isLogin && data.fitTypes.includes('LOCATION') ? Colors.C_GREEN_150 : Colors.C_COOL_GRAY_70}
                >
                  {data.address ?? NumberFormat.formatLocationFullName(data.locationCode)}
                </Text>
              </div>
              <div className={cx('fitItem', { hidden: !data.minSalary })}>
                <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60}>
                  연봉
                </Text>
                <Text
                  fontStyle={FontStyle.B3_M}
                  color={isLogin && data.fitTypes.includes('SALARY') ? Colors.C_GREEN_150 : Colors.C_COOL_GRAY_70}
                >
                  {NumberFormat.formatSalaryText(data.minSalary, data.maxSalary)}
                </Text>
              </div>
            </div>
          </div>

          <div className={cx('badgeArea')}>
            <div
              className={cx('badge', 'job', {
                hidden:
                  isLogin && (fitJobTitle || data.fitTypes.includes('LOCATION') || data.fitTypes.includes('SALARY')),
              })}
            >
              인기
            </div>
            <div className={cx('badge', 'job', { hidden: !fitJobTitle })}>직무 Fit</div>
            <div className={cx('badge', 'location', { hidden: !data.fitTypes.includes('LOCATION') })}>위치 Fit</div>
            <div className={cx('badge', 'salary', { hidden: !data.fitTypes.includes('SALARY') })}>연봉 Fit</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PersonalPositionListItem;
