import Skeleton from '@components/_atoms/Skeleton';

import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

const JobGroupPositionListSkeleton = () => {
  return (
    <div className={cx('section')}>
      <div className={cx('skeleton')}>
        <Skeleton width={200} height={32} />

        <div className={cx('jobGroup')}>
          <div className={cx('group')}>
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={20} />
          </div>

          <div className={cx('list')}>
            <Skeleton width={282} height={172} borderRadius={16} />
            <Skeleton width={282} height={172} borderRadius={16} />
            <Skeleton width={282} height={172} borderRadius={16} />
            <Skeleton width={282} height={172} borderRadius={16} />
            <Skeleton width={282} height={172} borderRadius={16} />
            <Skeleton width={282} height={172} borderRadius={16} />
            <Skeleton width={282} height={172} borderRadius={16} />
            <Skeleton width={282} height={172} borderRadius={16} />
            <Skeleton width={282} height={172} borderRadius={16} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobGroupPositionListSkeleton;
