import React from 'react';
import Layout from '@common/layout';
import Header from '@common/header';
import Content from '@common/content';
import Footer from '@common/footer';
import { IndexCustomHead } from '@common/customHead';
import JobPostModalWrap from '@common/jobPostModal/JobPostModalWrap';
import Index from '@components/index';

const IndexPage = () => {
  return (
    <Layout>
      <IndexCustomHead />
      <Header />

      <Content>
        <JobPostModalWrap>
          <Index />
        </JobPostModalWrap>
      </Content>

      <Footer />
    </Layout>
  );
};

export default IndexPage;
