import React from 'react';
import { useQueries } from 'react-query';
import PageTopButton from '@components/common/pageTopButton';
import JobPostList from '@components/index/jobPostList';
import PositionList from '@components/index/jobGroupPositionList';
import RecruitmentList from '@components/index/recruitmentList';
import ContentsList from '@components/index/contentsList';
import MainPageSkeleton from '@components/index/MainPageSkeleton';

import { fetchJobGroupList } from '@repository/codes/useCodes';
import { fetchPostCategoryList } from '@repository/post';
import {
  fetchAccPostingPosition,
  fetchAdsTitle,
  fetchJobPostingCurationPosition,
  fetchJobPostingRecommendPosition,
} from '@repository/job-postings/useJobPostings';
import { fetchMainBanners } from '@repository/ads/useAds';
import { fetchPositionJobGroups, fetchPositionProfileRecommend } from '@repository/position/usePosition';
import MainTopArea from './mainTopArea';
import PersonalPositionList from './personalPositionList';
import { AdsTitleEnum } from '@domain/constant/AdsTitleType';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const Index = () => {
  // 메인 페이지 내에서 호출하는 react-query 리스트
  // 전체 리스트가 성공적으로 호출되면 메인 페이지를 렌더링 / 그 전에는 스켈레톤을 렌더링
  const mainPageQueris = [
    { queryKey: ['ads', 'main-banner', 'mainBannerList'], queryFn: fetchMainBanners },
    {
      queryKey: ['job-postings', 'ads', 'title', AdsTitleEnum.CURATION_JOB_POSTING],
      queryFn: () => fetchAdsTitle(AdsTitleEnum.CURATION_JOB_POSTING),
    },
    {
      queryKey: ['position', 'profile', 'recommend', { hide: true, pageSize: 11 }],
      queryFn: () => fetchPositionProfileRecommend({ hide: true, pageSize: 11 }),
    },
    {
      queryKey: ['job-postings', 'PositionList', 'recommend', { page: 0, size: 24, hide: true }],
      queryFn: () => fetchJobPostingRecommendPosition({ page: 0, size: 24, hide: true }),
    },
    {
      queryKey: ['job-postings', 'ads', 'title', AdsTitleEnum.RECOMMEND_JOB_POSTING],
      queryFn: () => fetchAdsTitle(AdsTitleEnum.RECOMMEND_JOB_POSTING),
    },
    { queryKey: ['codes', 'JobGroup'], queryFn: fetchJobGroupList },
    {
      queryKey: ['position', 'PositionList', 'JobGroups', { jobGroupCode: 1, pageSize: 9, hide: true }],
      queryFn: () => fetchPositionJobGroups({ jobGroupCode: 1, pageSize: 9, hide: true }),
    },
    { queryKey: ['job-postings', 'acc', 'PositionList', 24], queryFn: () => fetchAccPostingPosition(24) },
    { queryKey: ['postJob', 'categories', 'space', 'job', 'MAIN'], queryFn: () => fetchPostCategoryList('MAIN') },
    { queryKey: ['job-postings', 'PositionList', 'Curation', 1], queryFn: () => fetchJobPostingCurationPosition(1) },
  ];

  const queryResults = useQueries(mainPageQueris);
  const isAllQueiresLoading = queryResults.some((query) => query.isLoading);

  if (isAllQueiresLoading) return <MainPageSkeleton />;

  return (
    <div className={cx('mainPageArea')}>
      {/* 메인 배너 영역 */}
      <MainTopArea />

      {/* 개인화 포지션 영역*/}
      <PersonalPositionList />

      {/* 지금 뜨는 공고 영역*/}
      <RecruitmentList />

      {/* 직군별 추천 공고 영역 */}
      <PositionList />

      {/* 역량검사 활용 공고 영역 */}
      <JobPostList />

      {/* 취업 콘텐츠 영역 */}
      <ContentsList />

      {/* 페이지 Top 버튼 영역 */}
      <PageTopButton />
    </div>
  );
};

export default Index;
