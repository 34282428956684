import Skeleton from '@components/_atoms/Skeleton';

import classnames from 'classnames/bind';
import styles from './mainBanner.module.scss';
const cx = classnames.bind(styles);

const MainBannerSkeleton = () => {
  return (
    <div className={cx('skeleton')}>
      <Skeleton width={792} height={260} borderRadius={12} />
      <Skeleton width={384} height={260} borderRadius={12} />
    </div>
  );
};

export default MainBannerSkeleton;
