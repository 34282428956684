import React from 'react';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  borderRadius?: React.CSSProperties['borderRadius'];
  duration?: React.CSSProperties['animationDuration'];
  delay?: React.CSSProperties['animationDelay'];
  className?: string;
  style?: React.CSSProperties;
  type?: 'slide' | 'blink';
}

const Skeleton = ({
  width,
  height,
  borderRadius,
  duration: animationDuration,
  delay: animationDelay,
  className,
  style,
  type,
  ...props
}: Props) => {
  return (
    <div
      className={cx('skeleton', className, type)}
      style={{ width, height, borderRadius, animationDuration, animationDelay, ...style }}
      {...props}
    />
  );
};

export default Skeleton;
