import React, { FC } from 'react';
import PositionTextListItem from '@components/common/positionTextListItem';
import PositionTextListItemVO from '@domain/vo/common/PositionTextListItemVO';
import { PositionJobGroupsDto } from '@domain/rs/position/PositionJobGroupsRs';
import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

// 포지션이 없는 경우
const EmptyList = () => {
  return (
    <div className={cx('emptyArea')}>
      <strong className={cx('emptyTitle')}>포지션을 기다리고 있어요.</strong>
      <p className={cx('emptyDesc')}>기업에서 해당 포지션의 채용을 시작하면 포지션을 확인할 수 있어요.</p>
    </div>
  );
};

interface PositionListProps {
  positionListData?: PositionJobGroupsDto[];
}

const PositionList: FC<PositionListProps> = ({ positionListData }) => {
  if (!positionListData) return null;
  if (!positionListData.length) return <EmptyList />;

  const positionListEl = positionListData.map((item) => {
    const positionData = new PositionTextListItemVO({
      ...item,
    });

    return <PositionTextListItem key={item.positionSn} positionData={positionData} />;
  });

  return <div className={cx('positionList')}>{positionListEl}</div>;
};

export default PositionList;
