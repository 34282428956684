import React, { FC } from 'react';
import { JobGroupDto } from '@domain/rs/profile/career/JobGroupRs';

import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

interface JobGroupCategoryListProps {
  selectedJobGroup: JobGroupDto | null;
  setSelectedJobGroup: (selected: JobGroupDto) => void;
  jobGroupsData: JobGroupDto[];
}

const JobGroupCategoryList: FC<JobGroupCategoryListProps> = ({
  selectedJobGroup,
  setSelectedJobGroup,
  jobGroupsData,
}) => {
  const jobGroupCategoryListEl = jobGroupsData.map((item) => {
    return (
      <li className={cx('listItem')} key={item.code}>
        <a
          className={cx('btnJob', { on: selectedJobGroup?.code === item.code }, `main_position_${item.code}`)}
          role="button"
          onClick={() => setSelectedJobGroup(item)}
        >
          {item.name}
        </a>
      </li>
    );
  });

  return <ul className={cx('jobTypeList')}>{jobGroupCategoryListEl}</ul>;
};

export default JobGroupCategoryList;
