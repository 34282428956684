import React, { useContext, useRef } from 'react';
import Slider from 'react-slick';
import Link from 'next/link';
import JobOpeningImageListItem from '@components/common/jobOpeningImageListItem';
import { JobOpeningImageListItemVO } from '@domain/vo/common/JobOpeningImageListItemVO';
import { JobPostModalContext } from '@components/common/jobPostModal/JobPostModalWrap';
import SliderButton from '@components/common/sliderButton';
import Icon from '@components/common/assets';

import { useAdsTitle, useJobPostingRecommendPosition } from '@repository/job-postings/useJobPostings';
import { AdsTitleEnum } from '@domain/constant/AdsTitleType';

import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './recruitmentList.module.scss';
const cx = classnames.bind(styles);

// 공고가 없는 경우
interface EmptyListProps {
  title: string;
}
const EmptyList = ({ title }: EmptyListProps) => {
  return (
    <div className={cx('section')}>
      <div className={cx('sectionInner')}>
        <div className={cx('recruitmentListArea')}>
          <div className={cx('sectionTitleArea')}>
            <strong className={cx('sectionTitle')}>{title}</strong>
          </div>

          <div className={cx('emptyArea')}>
            <strong className={cx('emptyTitle')}>공고를 기다리고 있어요.</strong>
            <p className={cx('emptyDesc')}>기업에서 채용을 시작하면 채용 공고를 확인할 수 있어요.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'prev'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnPrev')}
    />
  );
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'next'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnNext')}
    />
  );
};

const RecruitmentList = () => {
  const dragging = useRef(false);
  const settings = {
    infinite: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 1,
    speed: 300,
    rows: 2,
    slidesPerRow: 4,
    dots: true,
    cssEase: 'ease-in-out',
    accessibility: false, //화살표키 입력 막기
    draggable: true,
    beforeChange: () => (dragging.current = true),
    afterChange: () => (dragging.current = false),
  };
  const { onOpenJobPostModal } = useContext(JobPostModalContext);
  const { data: recommendPositionListData, isLoading: isPositionListLoading } = useJobPostingRecommendPosition({
    page: 0,
    size: 24,
    hide: true,
  });
  const { data: titleData, isLoading: isTitleLoading } = useAdsTitle(AdsTitleEnum.RECOMMEND_JOB_POSTING);
  const title = titleData?.title ? titleData.title : '지금은 공채 시즌, 인기있는 신입 공채 놓치지마요!';

  //이전 공고, 다음 공고 표시 이동하기 위한 용도
  const allPositionList =
    recommendPositionListData?.map((item) => {
      return { key: `${item.jobPostingSn}`, sn: item.jobPostingSn, name: item.companyName };
    }) ?? [];

  const recruitmentListItems = recommendPositionListData?.map((item) => {
    const positionData = new JobOpeningImageListItemVO({
      ...item,
      sn: item.jobPostingSn,
      positionName: item.jobPostingName,
    });

    return (
      <div className={cx('listItemWrap')} key={item.jobPostingSn}>
        <JobOpeningImageListItem
          visibleBookmark={false}
          positionData={positionData}
          onOpenJobPostModal={() => {
            if (dragging.current) return;
            onOpenJobPostModal(`${item.jobPostingSn}`, item.jobPostingSn, allPositionList);
          }}
        />
      </div>
    );
  });

  if (isPositionListLoading || isTitleLoading) return null;
  if (recommendPositionListData?.length === 0) return <EmptyList title={title} />;

  return (
    <div className={cx('section')}>
      <div className={cx('sectionInner')}>
        <div className={cx('recruitmentListArea')}>
          <div className={cx('sectionTitleArea')}>
            <strong className={cx('sectionTitle')}>지금 뜨는 공고</strong>
            <Link href={RouteUrl.JOBS} legacyBehavior>
              <a className={cx('btnViewAll')} role="button">
                전체 보기
                <Icon name="arrowRightLight" width={24} height={24} />
              </a>
            </Link>
          </div>

          <div className={cx('recruitmentListInner')}>
            <Slider {...settings}>{recruitmentListItems}</Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentList;
