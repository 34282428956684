import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import NoAffiliateAuthModal from '@components/common/modals/affiliateModal/noAffiliateAuthModal';
import NoMatchingUserModal from '@components/common/modals/affiliateModal/noMatcingUserModal';
import PostJobCategoryList from '@components/index/contentsList/PostJobCategoryList';
import PostJobThumbnailList from '@components/index/contentsList/PostJobThumbnailList';
import { usePostJobCategories } from '@repository/post/job/usePostJob';
import LoginModal from '@components/loginModal';
import { PostJobCategoryDto } from '@domain/rs/postJob/PostJobCategoryListRs';
import { RouteUrl } from '@utils/routeUtil';

import useContentAccessControl from '@utils/hooks/useContentAccessControl';
import classnames from 'classnames/bind';
import styles from './contentsList.module.scss';
const cx = classnames.bind(styles);

const ContentsList = () => {
  const [selectedCategory, setSelectedCategory] = useState<PostJobCategoryDto | null>(null);

  const { data: postJobCategoryData } = usePostJobCategories('MAIN');

  const {
    handleCardClick,
    loginModalVisible,
    noAffiliateAuthModalVisible,
    redirectUrl,
    setLoginModalVisible,
    setNoAffiliateAuthModalVisible,
    noAffiliateAuthModalTitle,
    noMatchingUserModalVisible,
    setNoMatchingUserModalVisible,
  } = useContentAccessControl();

  useEffect(() => {
    if (!postJobCategoryData) return;
    const randomIndex = Math.floor(Math.random() * postJobCategoryData.categories.length);
    setSelectedCategory(postJobCategoryData.categories[randomIndex]);
  }, [postJobCategoryData]);

  if (!postJobCategoryData || !selectedCategory) return null;

  return (
    <div className={cx('section')}>
      <div className={cx('sectionInner')}>
        <div className={cx('contentsListArea')}>
          <div className={cx('sectionTitleArea')}>
            <div className={cx('sectionTitle', 'main_contents')}>취업 콘텐츠</div>
            <Link href={RouteUrl.INFO_LIST} legacyBehavior>
              <a className={cx('btnViewAll')} role="button">
                전체 보기
                <Icon name="arrowRightLight" width={24} height={24} />
              </a>
            </Link>
          </div>

          <div className={cx('contentsListInner')}>
            <PostJobCategoryList
              categoryList={postJobCategoryData.categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />

            <PostJobThumbnailList
              AllPostCardSns={postJobCategoryData.categorySns}
              selectedCategory={selectedCategory}
              handleClick={handleCardClick}
            />
          </div>
        </div>
      </div>

      <LoginModal
        subTitle="채용정보 · 역량검사 · 매칭까지"
        title="취업기회 지금 바로 잡다!"
        visible={loginModalVisible}
        onClose={() => setLoginModalVisible(false)}
        redirectUrl={redirectUrl}
      />
      <NoAffiliateAuthModal
        title={noAffiliateAuthModalTitle}
        visible={noAffiliateAuthModalVisible}
        onClose={() => setNoAffiliateAuthModalVisible(false)}
      />
      <NoMatchingUserModal visible={noMatchingUserModalVisible} onClose={() => setNoMatchingUserModalVisible(false)} />
    </div>
  );
};

export default ContentsList;
