import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import JobGroupCategoryList from '@components/index/jobGroupPositionList/JobGroupCategoryList';
import PositionList from '@components/index/jobGroupPositionList/PositionList';
import { useJobGroupList } from '@repository/codes/useCodes';
import { usePositionJobGroups } from '@repository/position/usePosition';
import { JobGroupDto } from '@domain/rs/profile/career/JobGroupRs';
import { RouteUrl } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

const JobGroupPositionList = () => {
  const [selectedJobGroup, setSelectedJobGroup] = useState<JobGroupDto | null>(null);
  const { data: jobGroupListData } = useJobGroupList();

  const { data: positionJobGroupsData, isFetching } = usePositionJobGroups({
    jobGroupCode: selectedJobGroup?.code,
    pageSize: 9,
    hide: true,
  });

  useEffect(() => {
    //직군 목록 불러오기 최초 완료시 첫번째 직군 set함
    if (!selectedJobGroup && jobGroupListData?.jobTitleGroups.length) {
      setSelectedJobGroup(jobGroupListData?.jobTitleGroups[0]);
    }
  }, [jobGroupListData]);

  if (!jobGroupListData) return null;

  return (
    <div className={cx('section')}>
      <div className={cx('sectionInner')}>
        <div className={cx('positionListArea')}>
          <div className={cx('sectionTitleArea')}>
            <div className={cx('sectionTitle', 'main_position')}>직군별 추천 공고</div>
            <Link href={RouteUrl.POSITION} legacyBehavior>
              <a className={cx('btnViewAll')} role="button">
                전체 보기
                <Icon name="arrowRightLight" width={24} height={24} />
              </a>
            </Link>
          </div>

          <div className={cx('positionListInner')}>
            {/* 좌측 리스트 영역 */}
            <JobGroupCategoryList
              jobGroupsData={jobGroupListData?.jobTitleGroups}
              selectedJobGroup={selectedJobGroup}
              setSelectedJobGroup={setSelectedJobGroup}
            />

            <div className={cx('positionListWrap')}>
              {/* 우측 포지션 리스트 영역
                랜덤하게 바뀌는 응답으로 인해 렌더링 후 텍스트가 따닥 바뀌는 현상 발생
                → fetching 조건을 추가해 한 번에 렌더링 시킴 */}
              {!isFetching && <PositionList positionListData={positionJobGroupsData?.positions} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobGroupPositionList;
