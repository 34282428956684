import React, { useContext, useRef } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import Icon from '@common/assets';
import JobOpeningTextListItem from '@components/common/jobOpeningTextListItem';
import SliderButton from '@components/common/sliderButton';
import { JobOpeningTextListItemVO } from '@domain/vo/common/JobOpeningTextListItemVO';
import { JobPostModalContext } from '@components/common/jobPostModal/JobPostModalWrap';

import { useAccPostingPosition } from '@repository/job-postings/useJobPostings';
import { RouteUrl } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './jobPostList.module.scss';
const cx = classnames.bind(styles);

// 공고가 없는 경우
const EmptyList = () => {
  return (
    <div className={cx('section')}>
      <div className={cx('sectionInner')}>
        <div className={cx('jobPostListArea')}>
          <div className={cx('sectionTitleArea')}>
            <Link href={RouteUrl.ACCA_TEST_GATE} legacyBehavior>
              <a className={cx('sectionTitle')} role="button">
                🎯 역량검사 활용 공고
                <Icon name="arrowRightLight" width={32} height={32} />
              </a>
            </Link>
          </div>
          <p className={cx('sectionDesc')}>잡다에서 연습한 역량검사를 활용할 수 있어요.</p>

          <div className={cx('emptyArea')}>
            <strong className={cx('emptyTitle')}>역량검사 채용 공고를 기다리고 있어요.</strong>
            <p className={cx('emptyDesc')}>
              기업에서 역량검사로 채용을 시작하면 역량검사 채용 공고를 확인할 수 있어요.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'prev'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnPrev')}
    />
  );
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'next'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnNext')}
    />
  );
};

const JobPostList = () => {
  const dragging = useRef(false);
  const settings = {
    infinite: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 1,
    speed: 300,
    rows: 2,
    slidesPerRow: 4,
    cssEase: 'ease-in-out',
    draggable: true,
    beforeChange: () => (dragging.current = true),
    afterChange: () => (dragging.current = false),
  };

  const { onOpenJobPostModal } = useContext(JobPostModalContext);
  const { data: positionListData, isLoading } = useAccPostingPosition(24);
  //이전 공고, 다음 공고 표시 이동하기 위한 용도
  const allPositionList =
    positionListData?.jobPostings?.map((item) => {
      return { key: `${item.sn}`, sn: item.sn, name: item.companyName };
    }) ?? [];

  const jobPostListItems =
    positionListData?.jobPostings?.map((item) => {
      const positionData = new JobOpeningTextListItemVO({
        ...item,
      });

      return (
        <div className={cx('listItemWrap')} key={item.sn}>
          <JobOpeningTextListItem
            positionData={positionData}
            onClickCard={() => {
              if (dragging.current) return;
              onOpenJobPostModal(`${item.sn}`, item.sn, allPositionList);
            }}
          />
        </div>
      );
    }) ?? [];

  if (isLoading) return null;
  if (positionListData?.jobPostings?.length === 0) return <EmptyList />;

  return (
    <div className={cx('section')}>
      <div className={cx('sectionInner')}>
        <div className={cx('jobPostListArea')}>
          <div className={cx('sectionTitleArea')}>
            <div className={cx('sectionTitle', 'main_post_acca')}>
              역량검사 활용 공고
              <span className={cx('sectionDesc')}>잡다에서 연습한 역량검사를 활용할 수 있어요.</span>
            </div>
            <Link href={{ pathname: RouteUrl.ACCA_TEST_GATE, query: { move: 'jobPostings' } }} legacyBehavior>
              <a className={cx('btnViewAll')} role="button">
                전체 보기
                <Icon name="arrowRightLight" width={24} height={24} />
              </a>
            </Link>
          </div>
          <div className={cx('jobPostListInner')}>
            <Slider {...settings}>{jobPostListItems}</Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPostList;
