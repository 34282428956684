import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useAdsMainBannerRolling } from '@repository/ads/useAds';
import { LogTypeEnum } from '@/src/logging/LogType';
import useActiveTabFlag from '@utils/hooks/useActiveTabFlag';
import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const RollingBanner = () => {
  const [rotateX, setRotateX] = useState(0);
  const { data: mainBannerRollingAds } = useAdsMainBannerRolling();
  const sortMainBannerAds = mainBannerRollingAds?.adsMainBanners?.length
    ? mainBannerRollingAds?.adsMainBanners.sort((a, b) => a.priority - b.priority)
    : [
        {
          sn: 1, //배너 광고 순번
          priority: 1, //광고 노출 순서
          name: '잡다매칭, 역량만으로 취업하는 매칭 서비스', //배너 광고 명
          link: RouteUrl.MATCH_INTRODUCE, //배너 링크
        },
      ];
  const ANGLE = 360 / (sortMainBannerAds?.length ?? 0);
  const initFlag = useActiveTabFlag();

  useEffect(() => {
    if (!mainBannerRollingAds?.adsMainBanners?.length) return;
    const intervalId = setInterval(() => {
      setRotateX((prev) => prev - ANGLE);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [mainBannerRollingAds]);

  if (!initFlag) return null;

  return (
    <div className={cx('noticeBanner')}>
      <div className={cx('title')}>HOT</div>
      <div className={cx('rotatorArea')} id={LogTypeEnum.log_main_ad_rolling}>
        <div
          className={cx('rotator')}
          style={{
            transform: `rotateX(${rotateX}deg)`,
          }}
        >
          {sortMainBannerAds?.map((item, idx) => (
            <div
              key={item.sn}
              className={cx('item')}
              style={{ transform: `rotateX(${idx * ANGLE}deg) translateZ(50px)` }}
            >
              <Link href={item.link} target="_blank">
                {item.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RollingBanner;
