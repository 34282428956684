import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import AdsMainRollingBannerListRs from '@domain/rs/ads/AdsMainRollingBannerListRs';
import MainBannerListRs from '@domain/rs/ads/MainBannerListRs';

export default interface AdsRepo {
  //메인 배너 롤링 광고
  fetchAdsMainBannerRolling(): Promise<AxiosResponse<AdsMainRollingBannerListRs>>;

  //메인배너 광고 조회
  fetchMainBanners(): Promise<AxiosResponse<MainBannerListRs>>;

  //메인배너 광고 클릭수 증가
  fetchMainBannerClicksLog(mainBannerSn: number): Promise<AxiosResponse>;

  //메인배너 광고 노출수 증가
  fetchMainBannerShowsLog(mainBannerSn: number): Promise<AxiosResponse>;
}

export class RemoteAdsRepo implements AdsRepo {
  fetchAdsMainBannerRolling(): Promise<AxiosResponse<AdsMainRollingBannerListRs>> {
    return axios.get('/ads/main-banners/rolling');
  }

  fetchMainBanners() {
    return axios.get<MainBannerListRs>('/ads/main-banners');
  }

  fetchMainBannerClicksLog(mainBannerSn: number) {
    return axios.post(`/ads/${mainBannerSn}/views`);
  }

  fetchMainBannerShowsLog(mainBannerSn: number) {
    return axios.post(`/ads/${mainBannerSn}/impression`);
  }
}
