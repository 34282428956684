import React from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import PositionTextListItemVO from '@domain/vo/common/PositionTextListItemVO';
import EllipsisText from '@components/common/ellipsisText';
import { LogTypeEnum } from '@/src/logging/LogType';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from './positionTextListItem.module.scss';
const cx = classnames.bind(styles);

interface PositionTextListItemProps {
  positionData: PositionTextListItemVO;
}

const PositionTextListItem = ({
  positionData,
  isInCompanyList,
}: PositionTextListItemProps & { isInCompanyList?: boolean }) => {
  const { positionName, companyName, locationName, routeUrl, subInfoTextList } = positionData;

  const isJobPostingName = !!positionData.jobPosting?.jobPostingName;

  const subInfoTextListEl = subInfoTextList.map((item, index) => {
    return (
      <div className={cx('companySubInfoWrap')} key={index}>
        {item}
      </div>
    );
  });

  // 타이틀 - 공고명이 있는 경우 1줄, 없는 경우 2줄
  // 공고명 - 1줄 길면 가려지게
  const titleOffset = isJobPostingName ? 20 : 40;
  const titleEllipsisLineClassName = isJobPostingName ? 'one' : 'two';

  return (
    <Link
      className={cx('link', { jdLinkWidth: isInCompanyList })}
      href={routeUrl}
      id={LogTypeEnum.log_recommend_position}
    >
      <>
        <EllipsisText
          text={positionName}
          type={'height'}
          offset={titleOffset}
          customClassName={cx('positionTitleWrap', titleEllipsisLineClassName)}
        />
        {positionData.jobPosting?.jobPostingName && (
          <EllipsisText
            text={positionData.jobPosting.jobPostingName}
            type={'height'}
            offset={18}
            customClassName={cx('jobPostingName')}
          />
        )}
        <div className={cx('companyInfoArea')}>
          <div className={cx('companyNameWrap')}>
            <span className={cx('companyName')}>{companyName}</span>
          </div>
          {!!locationName && (
            <span className={cx('companyLocation')}>
              <Icon name="pinLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
              {locationName}
            </span>
          )}
        </div>
        {!!subInfoTextListEl.length && <div className={cx('companySubInfoArea')}>{subInfoTextListEl}</div>}
      </>
    </Link>
  );
};

export default PositionTextListItem;
