import React, { useState } from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import SliderButton from '@components/common/sliderButton';
import { Base64_COOL_GREY_30 } from '@components/common/image';

import { useMainBannerClicksLog, useMainBannerShowsLog, useMainBanners } from '@repository/ads/useAds';
import { LogTypeEnum } from '@/src/logging/LogType';
import { useLoggingContext } from '@/src/logging';
import TextFormat from '@utils/text/format';
import classnames from 'classnames/bind';
import styles from './mainBanner.module.scss';

const cx = classnames.bind(styles);

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'prev'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnPrev')}
    />
  );
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'next'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnNext')}
    />
  );
};

const MainBanner = () => {
  const { data: mainBannerData } = useMainBanners();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { mutate: mainBannerClicksLogMutate } = useMainBannerClicksLog();
  const { mutate: mainBannerShowsLogMutate } = useMainBannerShowsLog();
  const [dragging, setDragging] = useState(false);
  const [viewBannerSn, setViewBannerSn] = useState<number[]>([]);
  const { handleLoggingNewTab } = useLoggingContext();

  const isViewMainBanner = (sn?: number) => {
    if (!sn) return false;
    return viewBannerSn.includes(sn);
  };

  const settings = {
    centerMode: false,
    variableWidth: false,
    infinite: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dots: false,
    afterChange: (index: number) => {
      setCurrentSlide(index);
      setDragging(false);
    },
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 700,
    cssEase: 'ease-in-out',
    beforeChange: (index: number) => {
      //mainBannerShowsLogMutate가 실패하면 setViewBannerSn을 실행하지 않기 위해 try-catch문 사용
      try {
        setDragging(true);
        const mainBannerSn = mainBannerData?.adsMainBanners?.[index]?.sn;
        //광고를 보지 않았다면 viewCount o
        if (mainBannerSn && !isViewMainBanner(mainBannerData?.adsMainBanners?.[index].sn)) {
          //슬라이드 보여진 횟수 기록 api
          mainBannerShowsLogMutate(mainBannerSn);
          setViewBannerSn([...viewBannerSn, mainBannerSn]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    draggable: true,
  };

  const mainBannerListItems =
    mainBannerData?.adsMainBanners?.map((item) => {
      const { sn, pcBannerImageUrl, bannerLink, name } = item;

      const handleClick: React.MouseEventHandler = (e) => {
        if (dragging) {
          e.stopPropagation();
          return;
        }
        mainBannerClicksLogMutate(sn);
        handleLoggingNewTab(e as any, bannerLink);
        window.open(TextFormat.formatProtocol(bannerLink));
      };

      return (
        <div key={sn} className={cx('mainBannerWrap')} id={LogTypeEnum.log_main_ad_banner}>
          <a className={cx('mainBanner')} role={'button'} onClick={handleClick}>
            <Image
              unoptimized
              src={pcBannerImageUrl}
              alt={name}
              fill
              placeholder="blur"
              blurDataURL={Base64_COOL_GREY_30}
              objectFit="cover"
            />
          </a>
        </div>
      );
    }) ?? [];

  return (
    <div className={cx('mainBannerArea')}>
      <Slider {...settings}>{mainBannerListItems}</Slider>
      <div className={cx('pageNumber')}>
        <span className={cx('currentNum')}>{currentSlide + 1}</span> {mainBannerListItems.length}
      </div>
    </div>
  );
};

export default MainBanner;
