import PageableRq from '../common/PageableRq';

export enum MatchingStatusListTabEnum {
  RECOMMEND = 'RECOMMEND',
  MATCH_OFFER = 'MATCH_OFFER',
  MATCH_INTERVIEW_OFFER = 'MATCH_INTERVIEW_OFFER',
}
export type MatchingStatusListTabType = keyof typeof MatchingStatusListTabEnum;

export default class MatchingStatusListRq extends PageableRq {
  tabType?: MatchingStatusListTabType;

  constructor({ page = 0, size = 30, tabType }: Partial<MatchingStatusListRq> = {}) {
    super({ page, size });
    this.tabType = tabType;
  }
}
