import { useState, useEffect, useCallback } from 'react';
import useDebouncedCallback from './useDebounceCallBack';

const useCustomOptimistic = (initValue: boolean, cb: (value: boolean) => void, delay: number, immediate?: boolean) => {
  const [currentValue, setCurrentValue] = useState(initValue);
  const [viewValue, setViewValue] = useState(initValue);

  const onError = useCallback(() => {
    setViewValue(currentValue);
  }, [currentValue]);

  const onSuccess = useCallback(() => {
    setCurrentValue(viewValue);
  }, [viewValue]);

  const updateValue = useCallback(() => {
    setViewValue((prev) => !prev);
  }, []);

  const debounceFn = useDebouncedCallback(
    () => {
      if (currentValue === viewValue) return;
      cb(viewValue);
    },
    delay,
    immediate,
    [currentValue, viewValue],
  );

  useEffect(() => {
    debounceFn();
  }, [viewValue, debounceFn]);

  return { viewValue, updateValue, optimisticError: onError, optimisticSuccess: onSuccess };
};

export default useCustomOptimistic;
