import PageTopButton from '@components/common/pageTopButton';
import MainBannerSkeleton from '@components/index/mainTopArea/mainBanner/MainBannerSkeleton';
import RecruitmentListSkeleton from '@components/index/recruitmentList/RecruitmentListSkeleton';
import JobGroupPositionListSkeleton from '@components/index/jobGroupPositionList/JobGroupPositionListSkeleton';
import JobPostListSkeleton from '@components/index/jobPostList/JobPostListSkeleton';
import ContentsListSkeleton from '@components/index/contentsList/ContentsListSkeleton';

import PersonalPositionSkeleton from '@components/index/personalPositionList/PersonalPositionSkeleton';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const MainPageSkeleton = () => {
  return (
    <div className={cx('mainPageArea')}>
      {/* 메인 배너 영역 스켈레톤 */}
      <MainBannerSkeleton />

      {/* 맞춤 포지션 스켈레톤 */}
      <PersonalPositionSkeleton />

      {/* 공채 영역 스켈레톤 */}
      <RecruitmentListSkeleton />

      {/* 인기 포지션 영역 스켈레톤 */}
      <JobGroupPositionListSkeleton />

      {/* 역량검사 활용 공고 영역 스켈레톤 */}
      <JobPostListSkeleton />

      {/* 취업 콘텐츠 영역 스켈레톤 */}
      <ContentsListSkeleton />

      {/* 페이지 Top 버튼 영역 */}
      <PageTopButton />
    </div>
  );
};

export default MainPageSkeleton;
