import Skeleton from '@components/_atoms/Skeleton';

import classnames from 'classnames/bind';
import styles from './contentsList.module.scss';
const cx = classnames.bind(styles);

const ContentsListSkeleton = () => {
  return (
    <div className={cx('section')}>
      <div className={cx('skeleton')}>
        <Skeleton width={200} height={32} />
        <div className={cx('category')}>
          <Skeleton width={32} height={20} />
          <Skeleton width={80} height={20} />
          <Skeleton width={80} height={20} />
          <Skeleton width={80} height={20} />
        </div>

        <div className={cx('list')}>
          {Array.from({ length: 4 }, (_, i) => (
            <div className={cx('item')} key={i}>
              <Skeleton width={282} height={212} />
              <Skeleton width={75} height={18} style={{ marginTop: 24 }} />
              <Skeleton width={282} height={40} style={{ marginTop: 18 }} />
              <Skeleton width={200} height={32} style={{ marginTop: 16 }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentsListSkeleton;
